<template>
  <v-container
    fluid
    class="custom-line-item-template pb-0"
    v-if="getPermission('line-item:create')"
  >
    <v-row v-if="allowItemSelection">
      <v-col lg="6" md="4" sm="4" class="pt-0">
        <v-autocomplete
          v-model.trim="item"
          :items="serverItemList"
          :search-input.sync="serverSearch"
          clearable
          hide-details
          dense
          filled
          flat
          ref="search-item"
          :loading="serverSearchLoading || productLoading"
          label="Start typing to Search..."
          placeholder="Start typing to Search..."
          solo
          no-filter
          item-color="cyan"
          color="cyan"
          :disabled="productLoading || pageLoading"
          item-text="name"
          item-value="id"
          v-on:change="updateLineItem(item)"
          class="remove-border-radius hide-border-bottom"
          return-object
        >
          <template v-slot:no-data>
            <v-list-item>
              <v-list-item-title v-if="serverSearchLoading">
                Getting results...
                <v-progress-linear
                  color="cyan"
                  indeterminate
                  rounded
                  height="6"
                ></v-progress-linear>
              </v-list-item-title>
              <v-list-item-title v-else>
                No Product<template v-if="!isPurchaseOrder"
                  ><template v-if="!isContract">/Service</template
                  >/Equipment</template
                >
                Found
              </v-list-item-title>
            </v-list-item>
          </template>
          <template v-slot:item="{ item }">
            <v-list-item-avatar
              :color="lodash.isEmpty(item.primary_image) ? 'cyan' : 'white'"
              size="70"
              class="headline font-weight-light white--text text-uppercase"
            >
              <template v-if="lodash.isEmpty(item.primary_image) === false"
                ><v-img :src="$assetAPIURL(item.primary_image)"></v-img
              ></template>
              <template v-else>{{ item.name.charAt(0) }}</template>
            </v-list-item-avatar>
            <v-list-item-content class="max-width-350px">
              <v-list-item-title
                class="font-size-16 text-truncate pb-1 text-capitalize"
                v-text="item.name"
              ></v-list-item-title>
              <v-list-item-subtitle
                v-if="item.description"
                class="font-size-14 text-truncate pb-1"
                v-text="item.description"
              ></v-list-item-subtitle>
              <template v-if="isProduct(item)">
                <v-list-item-subtitle class="font-size-14 pb-1">
                  <span class="font-weight-500">Serial Number: </span>
                  <template v-if="item.serial_number">
                    {{ item.serial_number }}</template
                  >
                  <template v-else>No Serial Number</template>
                </v-list-item-subtitle>
                <v-list-item-subtitle class="font-size-14 pb-1">
                  <span class="font-weight-500">Part Number: </span>
                  <template v-if="item.part_number">
                    {{ item.part_number }}</template
                  >
                  <template v-else>No Part Number</template>
                </v-list-item-subtitle>
                <template v-if="false">
                  <v-list-item-subtitle class="font-size-14 pb-1"
                    ><span class="font-weight-500">Selling Cost:</span>
                    {{ formatNumber(item.selling_cost) }}</v-list-item-subtitle
                  >
                  <v-list-item-subtitle class="font-size-14"
                    ><span class="font-weight-500">Available Stock:</span>
                    {{
                      accountingUnFormat(item.available_stock)
                    }}</v-list-item-subtitle
                  >
                </template>
              </template>
              <template v-else>
                <v-list-item-subtitle class="font-size-14 pb-1"
                  ><span class="font-weight-500">Charges:</span>
                  {{ formatNumber(item.charges) }}</v-list-item-subtitle
                >
              </template>
            </v-list-item-content>
          </template>
        </v-autocomplete>
      </v-col>
    </v-row>
    <v-row class="custom-border-bottom first-border">
      <v-col class="py-0">
        <label for="" class="font-size-16 pa-2">
          Product<template v-if="!isPurchaseOrder"
            ><template v-if="!isContract">/Service</template
            >/Equipment</template
          >
        </label>
      </v-col>
      <v-col class="py-0" md="2">
        <label for="" class="font-size-16 pa-2">
          Qty.
          <v-tooltip top content-class="custom-top-tooltip">
            <template v-slot:activator="{ on, attrs }">
              <v-icon class="mx-2" v-bind="attrs" v-on="on"
                >mdi-progress-question</v-icon
              >
            </template>
            <span>Maximum allowed Qty is 9999</span>
          </v-tooltip>
        </label>
      </v-col>
      <v-col class="py-0" md="2" v-if="!isTicket">
        <label for="" class="font-size-16 pa-2">Rate</label>
      </v-col>
      <v-col class="py-0" md="2" v-if="false">
        <label for="" class="font-size-16 pa-2">Tax</label>
      </v-col>
      <v-col class="py-0" md="2" v-if="!isTicket">
        <label for="" class="font-size-16 pa-2">Total</label>
      </v-col>
    </v-row>
    <template v-if="lineItems.length < 1">
      <v-row class="custom-border-bottom">
        <v-col class="py-0" md="12">
          <p
            class="m-0 my-4 row-not-found text-center font-weight-500 font-size-16"
          >
            <img
              width="30"
              :src="$assetURL('media/error/empty.png')"
              class="row-not-found-image mr-4"
            />
            Uhh... There are no line item at the moment.
          </p>
        </v-col>
      </v-row>
    </template>
    <template v-else>
      <v-row
        v-for="(item, index) in lineItems"
        :key="index"
        :class="{ 'custom-border-bottom': !lineItems[index + 1] && !isTicket }"
      >
        <v-col>
          <template v-if="item.product">
            <v-layout>
              <template
                v-if="isQuotation && isBlankItem(item.product, item) === false"
              >
                <v-flex class="flex-grow-0" style="max-width: 60px">
                  <div class="v-text-field v-input d-block pt-0 mt-0">
                    <input
                      v-mask="'A'"
                      v-model.trim="item.group_me"
                      class="custom-grey-border p-1 d-block w-100 text-center"
                      style="padding: 19px 0px !important"
                      v-on:keyup="updateItemGroup()"
                    />
                  </div>
                </v-flex>
              </template>
              <v-flex class="flex-grow-1">
                <v-text-field
                  dense
                  filled
                  flat
                  color="cyan"
                  :loading="productLoading"
                  :disabled="productLoading || pageLoading"
                  label="Product"
                  hide-details
                  solo
                  v-model="item.product.name"
                  class="remove-border-radius"
                  v-on:keyup="calculateGrandTotal()"
                  v-on:change="calculateGrandTotal()"
                >
                </v-text-field>
              </v-flex>
            </v-layout>
          </template>
          <v-textarea
            v-model.trim="item.description"
            auto-grow
            dense
            filled
            flat
            :loading="productLoading"
            :disabled="productLoading || pageLoading"
            label="Description"
            solo
            color="cyan"
            class="remove-border-radius"
            hide-details
            row-height="20"
            v-on:keyup="calculateTotalAmount(index)"
          ></v-textarea>
        </v-col>
        <v-col md="2">
          <template>
            <v-text-field
              v-model.trim.number="item.quantity"
              dense
              filled
              flat
              color="cyan"
              :loading="productLoading"
              :disabled="productLoading || pageLoading"
              label="Qty."
              type="number"
              min="0"
              max="9999"
              v-mask="'####'"
              hide-details
              solo
              :rules="[
                validateRules.required(item.quantity, 'Qty.'),
                validateRules.validateQty(item.quantity, 9999, 'Qty.'),
              ]"
              :suffix="getQtySuffix(item.product)"
              :readonly="!isProduct(item.product) || !item.product"
              class="remove-border-radius"
              v-on:keyup="calculateTotalAmount(index)"
              v-on:change="calculateTotalAmount(index)"
              v-on:mouseup="calculateTotalAmount(index)"
            >
            </v-text-field>
            <template
              v-if="isQuotation && item.product.product_type != 'equipment'"
            >
              <v-tooltip top content-class="custom-top-tooltip">
                <template v-slot:activator="{ on, attrs }">
                  <v-select
                    v-model="item.quantity_type"
                    dense
                    filled
                    flat
                    v-bind="attrs"
                    v-on="on"
                    color="cyan"
                    item-color="cyan"
                    :readonly="!item.product"
                    :loading="productLoading"
                    :disabled="productLoading || pageLoading"
                    :rules="[
                      validateRules.required(
                        item.quantity_type,
                        `Item ${index + 1} UOM`
                      ),
                    ]"
                    :items="uomList"
                    item-text="text"
                    item-value="text"
                    label="UOM"
                    hide-details
                    solo
                    v-on:change="calculateGrandTotal"
                  ></v-select>
                </template>
                <span>UOM</span>
              </v-tooltip>
            </template>
            <template
              v-if="
                (isContract || isTicket) &&
                lodash.isEmpty(item.product) === false
              "
            >
              <template v-if="lodash.isEmpty(item.warranty_object) === false">
                <div class="text-center">
                  <v-checkbox
                    label="Warranty ?"
                    :value="1"
                    :disabled="pageLoading"
                    hide-details
                    color="cyan"
                    v-model.trim="item.warranty"
                    v-on:click="manageWarranty(index)"
                    class="my-2 remove-border-radius remove-border"
                  ></v-checkbox>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        tile
                        :disabled="pageLoading || !item.warranty"
                        class="my-2 width-100 custom-bold-button"
                        color="cyan"
                        outlined
                        small
                        v-bind="attrs"
                        v-on="on"
                        v-on:click="manageWarranty(index)"
                        ><v-icon left small>mdi-cog</v-icon
                        ><strong>Warranty</strong>
                      </v-btn>
                    </template>
                    <span>Manage Warranty</span>
                  </v-tooltip>
                </div>
              </template>
              <template v-else-if="isProduct(item.product)">
                <v-chip class="ma-2" color="red" outlined> No Warranty </v-chip>
              </template>
            </template>
          </template>
          <template v-if="false">
            <span>-</span>
          </template>
          <template v-if="isSingle">
            <template v-if="item.product.product_type == 'goods'">
              <v-tooltip top content-class="custom-top-tooltip">
                <template v-slot:activator="{ on, attrs }">
                  <v-autocomplete
                    v-bind="attrs"
                    v-on="on"
                    dense
                    filled
                    color="cyan"
                    item-color="cyan"
                    :items="categoryList"
                    label="Category"
                    solo
                    hide-details
                    flat
                    item-text="text"
                    item-value="id"
                    class="width-100"
                    v-model="item.category"
                    v-on:change="calculateGrandTotal()"
                  >
                    <template v-slot:no-data>
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title
                            v-html="'No Category(s) Found.'"
                          ></v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                  </v-autocomplete>
                </template>
                <span>Category</span>
              </v-tooltip>
              <v-tooltip top content-class="custom-top-tooltip">
                <template v-slot:activator="{ on, attrs }">
                  <v-autocomplete
                    dense
                    filled
                    v-bind="attrs"
                    v-on="on"
                    color="cyan"
                    item-color="cyan"
                    :items="supplierList"
                    label="Supplier"
                    solo
                    hide-details
                    flat
                    item-text="display_name"
                    item-value="id"
                    class="width-100"
                    v-model="item.supplier"
                    v-on:change="calculateGrandTotal()"
                  >
                    <template v-slot:no-data>
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title
                            v-html="'No Supplier(s) Found.'"
                          ></v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                  </v-autocomplete>
                </template>
                <span>Supplier</span>
              </v-tooltip>
            </template>
          </template>
        </v-col>
        <v-col md="2" v-if="!isTicket">
          <template v-if="isGroup(item)">
            <v-text-field
              v-model.trim.number="item.rate"
              dense
              filled
              flat
              type="number"
              min="0"
              color="cyan"
              :loading="productLoading"
              :disabled="productLoading || pageLoading"
              prepend-inner-icon="mdi-currency-usd"
              label="Rate"
              hide-details
              solo
              :readonly="!item.product"
              class="remove-border-radius prepend-currency"
              v-on:keyup="
                item.default_rate = item.rate;
                calculateTotalAmount(index);
              "
              v-on:change="calculateTotalAmount(index)"
              v-on:mouseup="calculateTotalAmount(index)"
            ></v-text-field>
          </template>
        </v-col>
        <v-col md="2" v-if="false">
          <v-select
            :items="taxes"
            dense
            filled
            flat
            hide-details
            solo
            color="cyan"
            item-color="cyan"
            :loading="productLoading"
            :disabled="productLoading || pageLoading"
            placeholder="Tax Rate"
            item-text="name"
            item-value="id"
            :readonly="!item.product"
            v-model.trim="item.taxObject"
            class="remove-border-radius m-0 width-100"
            clearable
            return-object
            v-on:change="calculateTotalAmount(index)"
          >
            <template v-slot:selection="data">
              {{ data.item.name }} - [{{ data.item.percentage }}%]
            </template>
            <template v-slot:item="data">
              {{ data.item.name }} - [{{ data.item.percentage }}%]
            </template>
          </v-select>
        </v-col>
        <v-col md="2" v-if="!isTicket">
          <template v-if="isGroup(item)">
            <v-text-field
              v-model.trim="item.total"
              dense
              filled
              flat
              color="cyan"
              :loading="productLoading"
              :disabled="productLoading || pageLoading"
              prepend-inner-icon="mdi-currency-usd"
              label="Total"
              hide-details
              solo
              readonly
              class="remove-border-radius prepend-currency"
            ></v-text-field>
          </template>
          <template v-if="lineItems.length > 1 && !isSingle">
            <div class="text-right">
              <v-btn
                tile
                :loading="productLoading || pageLoading"
                :disabled="pageLoading"
                class="custom-bold-button"
                color="red"
                outlined
                small
                :class="{
                  'my-2':
                    (item.group_active && item.group_primary) ||
                    (!item.group_active && !item.group_primary),
                }"
                v-on:click="removeLineItem(index, item.id)"
                ><strong>Delete</strong>
              </v-btn>
            </div>
          </template>
        </v-col>
      </v-row>
    </template>
    <WarrantyManage
      v-on:remove:manage-warranty="removeWarranty"
    ></WarrantyManage>
  </v-container>
</template>
<script>
import CommonFunctionMixin from "@/core/lib/common/common.functions.mixin";
import { QUERY, GET, DELETE } from "@/core/services/store/request.module";
import { GET_TAXES } from "@/core/services/store/common.module";
import ValidationMixin from "@/core/plugins/validation-mixin";
import { LineItemEventBus } from "@/core/lib/line-item/line.item.lib";
import WarrantyManage from "@/view/pages/partials/Warranty-Manage.vue";
import { WarrantyEventBus } from "@/core/lib/warranty/warranty.lib";
import ObjectPath from "object-path";

export default {
  name: "line-items",
  mixins: [ValidationMixin, CommonFunctionMixin],
  props: {
    isSingle: {
      type: Boolean,
      default: false,
    },
    isPurchaseOrder: {
      type: Boolean,
      default: false,
    },
    isTicket: {
      type: Boolean,
      default: false,
    },
    isQuotation: {
      type: Boolean,
      default: false,
    },
    customer: {
      type: [Number],
    },
    isContract: {
      type: Boolean,
      default: false,
    },
    isNewItem: {
      type: Boolean,
      default: false,
    },
    pageLoading: {
      type: Boolean,
      required: true,
      default: false,
    },
    updateData: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  components: {
    WarrantyManage,
  },
  data() {
    return {
      item: {},
      serverItemList: [],
      serverSearch: null,
      serverSearchLoading: false,
      productLoading: true,
      searchTimeout: null,
      searchTimeoutLimit: 500,
      grandTotalTimeout: null,
      grandTotalTimeoutLimit: 200,
      lineItems: [],
      taxes: [],
      uomList: [],
      categoryList: [],
      supplierList: [],
    };
  },
  watch: {
    serverSearch(previousValue, newValue) {
      if (previousValue != newValue) {
        this.getLineItems();
      }
    },
    updateData: {
      deep: true,
      immediate: true,
      handler(param) {
        if (this.lodash.isArray(param) && param.length > 0) {
          this.setUpdateData(param);
        }
      },
    },
  },
  methods: {
    isBlankItem(product, item) {
      if (product && product.hasOwnProperty("show_name")) {
        if (!product.show_name) {
          return true;
        }
      }
      if (item && item.hasOwnProperty("product")) {
        const { product } = item;
        if (product && product.hasOwnProperty("show_name")) {
          if (!product.show_name) {
            return true;
          }
        }
      }
      return false;
    },
    setUpdateData(param) {
      const _this = this;
      _this.productLoading = false;
      for (let i = 0; i < param.length; i++) {
        const {
          id,
          group_me,
          group_active,
          category,
          supplier,
          group_primary,
          original_product,
          description,
          quantity,
          quantity_type,
          tax,
          discount,
          rate,
          total,
          warranty,
          warranty_unique_id,
          warranty_start_date,
          warranty_data,
          warranty_description,
        } = param[i];

        let warranty_object = [];
        if (_this.lodash.isArray(warranty_data) && warranty_data.length > 0) {
          warranty_object = warranty_data.map((row) => {
            return {
              id: row.id,
              warranty: _this.lodash.toSafeInteger(row.warranty) || null,
              value: _this.lodash.toSafeInteger(row.value),
              field: row.field,
              unique_id: null,
            };
          });
        }

        _this.lineItems.push({
          id,
          group_me,
          group_active,
          group_primary,
          type: "product",
          product: _this.lodash.cloneDeep(original_product),
          description,
          quantity,
          category,
          supplier,
          quantity_type,
          tax: tax ? tax.id : null,
          taxObject: tax ?? null,
          discount,
          rate,
          default_rate: rate,
          total,
          warranty: +!!warranty,
          warranty_line_item_id: null,
          warranty_object,
          warranty_option: {
            unique_id: warranty_unique_id,
            start_date: warranty_start_date,
            description: warranty_description,
          },
        });
      }
      _this.updateItemGroup();
    },
    isGroup(item) {
      if (item.group_active) {
        if (item.group_primary) {
          return true;
        }
        return false;
      }
      return true;
    },
    getQtySuffix(product) {
      if (product.product_type != "equipment") {
        return "";
      }
      if (!this.levelOneHide && product) {
        return this.lodash.isEmpty(product.unit) === false
          ? product.unit.text
          : "";
      }
      return "";
    },
    isProduct(product) {
      if (product) {
        if (
          product.product_type == "goods" ||
          product.product_type == "equipment"
        ) {
          return true;
        }
        return false;
      }
      return true;
    },
    updateLineItem() {
      const _this = this;
      try {
        const item = _this.lodash.cloneDeep(_this.item);
        const warrantyObject = item.warranty_data.map((row) => {
          return {
            id: null,
            warranty: _this.lodash.toSafeInteger(row.id) || null,
            value: _this.lodash.toSafeInteger(row.value),
            field: row.field,
            unique_id: null,
          };
        });
        const taxObject =
          _this.levelOneHide === false &&
          _this.lodash.isEmpty(item.tax) === false
            ? item.tax
            : null;
        const rate =
          item.product_type == "goods"
            ? _this.lodash.toNumber(item.selling_cost)
            : _this.lodash.toNumber(item.charges);
        _this.lineItems.push({
          id: null,
          group_me: null,
          group_active: false,
          group_primary: false,
          type: "product",
          product: item,
          description: item.description,
          quantity: 1,
          quantity_type: item.product_type == "service" ? "lot" : "block",
          tax: taxObject ? taxObject.id : null,
          taxObject: taxObject ?? null,
          discount: 0.0,
          rate: rate ?? 0.0,
          default_rate: rate ?? 0.0,
          total: rate ?? 0.0,
          warranty: false,
          warranty_line_item_id: null,
          warranty_object: warrantyObject,
          warranty_option: new Object(),
        });

        _this.item = {};
        _this.calculateGrandTotal();
      } catch (error) {
        _this.logError(error);
      }
    },
    updateItemGroup() {
      const _this = this;
      try {
        const groups = [];
        const group_rate = {};
        for (let i = 0; i < _this.lineItems.length; i++) {
          const { group_me, default_rate } = _this.lineItems[i];

          if (_this.lineItems[i].group_me) {
            _this.lineItems[i].group_me = _this.lineItems[i].group_me
              .toString()
              .toUpperCase();
            _this.lineItems[i].group_primary = true;
            _this.lineItems[i].group_active = true;
            if (_this.lodash.includes(groups, _this.lineItems[i].group_me)) {
              _this.lineItems[i].group_primary = false;
            } else {
              groups.push(_this.lineItems[i].group_me);
            }

            if (!_this.lodash.isArray(group_rate[group_me])) {
              group_rate[group_me] = new Array();
            }
            group_rate[group_me].push(default_rate);
          } else {
            _this.lineItems[i].group_primary = false;
            _this.lineItems[i].group_active = false;
            _this.lineItems[i].rate = default_rate;
          }
        }

        for (const property in group_rate) {
          const g_index = _this.lodash.findIndex(_this.lineItems, {
            group_me: property,
            group_primary: true,
            group_active: true,
          });
          if (g_index >= 0) {
            _this.lineItems[g_index].rate = _this.lodash.toNumber(
              _this.lodash.sum(group_rate[property])
            );
            _this.calculateTotalAmount(g_index);
          }
        }
        _this.calculateGrandTotal();
      } catch (error) {
        _this.logError(error);
      }
    },
    calculateTotalAmount(index) {
      try {
        const item = this.lineItems[index] ?? null;
        if (item) {
          const { group_active, rate, quantity, taxObject } = item;
          if (!group_active) {
            if (!rate) return false;
          }
          if (!quantity) return false;
          const subTotal = rate * quantity;
          const taxId = taxObject ? taxObject.id : null;
          const taxPercentage = taxObject ? taxObject.percentage : 0;
          const taxAmount = (subTotal * taxPercentage) / 100;
          this.lineItems[index].tax = taxId;
          this.lineItems[index].total = subTotal + taxAmount;
          this.calculateGrandTotal();
        }
      } catch (error) {
        this.logError(error);
        this.removeLineItem(index);
      }
    },
    removeLineItem(index, id) {
      const _this = this;
      if (id > 0) {
        _this.$store
          .dispatch(DELETE, { url: "line-item/" + id })
          .then(() => {
            _this.lineItems.splice(index, 1);
            _this.calculateGrandTotal();
          })
          .catch((error) => {
            _this.logError(error);
          });
      } else {
        _this.lineItems.splice(index, 1);
        _this.calculateGrandTotal();
      }
    },
    calculateGrandTotal() {
      const _this = this;
      clearTimeout(_this.grandTotalTimeout);
      const lineItems = _this.lodash.cloneDeep(_this.lineItems);
      for (let i = 0; i < lineItems.length; i++) {
        lineItems[i].name = ObjectPath.get(lineItems[i], "product.name");
      }
      _this.grandTotalTimeout = setTimeout(function () {
        LineItemEventBus.$emit("update:line-item", lineItems);
      }, _this.grandTotalTimeoutLimit);
    },
    getLineItems() {
      const _this = this;
      if (!_this.allowItemSelection) {
        return false;
      }
      const search = _this.serverSearch ?? null;
      if (!search && _this.serverItemList.length > 0) {
        return false;
      }
      _this.serverSearchLoading = true;
      _this.serverItemList = [];
      clearTimeout(_this.searchTimeout);
      _this.searchTimeout = setTimeout(function () {
        _this.$store
          .dispatch(QUERY, {
            url: "product/get-line-items",
            data: {
              search,
              purchaseorder: +_this.isPurchaseOrder,
              customer: _this.customer,
              contract: +_this.isContract,
            },
          })
          .then(({ data }) => {
            _this.serverItemList = data;
          })
          .catch((error) => {
            _this.logError(error);
          })
          .finally(() => {
            _this.serverSearchLoading = false;
            _this.productLoading = false;
          });
      }, _this.searchTimeoutLimit);
    },
    getCustomLineItems(products) {
      const _this = this;
      return new Promise((resolve, reject) => {
        if (!products) {
          resolve([]);
        } else {
          _this.$store
            .dispatch(QUERY, {
              url: "product/get-line-items",
              data: { products },
            })
            .then(({ data }) => {
              resolve(data);
            })
            .catch((error) => {
              reject(error);
            });
        }
      });
    },
    getUoms() {
      const _this = this;
      if (!_this.isQuotation) {
        return false;
      }
      _this.$store
        .dispatch(GET, { url: "setting/product-unit" })
        .then(({ data }) => {
          _this.uomList = data.product_unit;
        })
        .catch((error) => {
          _this.logError(error);
        });
    },
    getEditOptions() {
      const _this = this;
      _this.$store
        .dispatch(QUERY, {
          url: "product/options",
          data: { product_type: "goods" },
        })
        .then(({ data }) => {
          _this.categoryList = data.category;
          _this.supplierList = data.supplier;
        })
        .catch((error) => {
          _this.logError(error);
        });
    },
    getTaxes() {
      const _this = this;
      _this.$store
        .dispatch(GET_TAXES)
        .then((response) => {
          _this.taxes = response.data;
        })
        .catch((error) => {
          _this.logError(error);
        });
    },
    removeWarranty(index) {
      this.lineItems[index].warranty = 0;
      WarrantyEventBus.$emit("close:manage-warranty");
      this.calculateGrandTotal();
    },
    manageWarranty(index) {
      const _this = this;
      const { warranty, product, warranty_object, warranty_option } =
        _this.lineItems[index];
      if (
        warranty &&
        _this.lodash.isEmpty(product) === false &&
        _this.lodash.isEmpty(warranty_object) === false
      ) {
        const defaultWarranty = warranty_object.map((row) => {
          return {
            current: index,
            warranty: _this.lodash.toSafeInteger(row.warranty),
            value: _this.lodash.toSafeInteger(row.value),
            field: row.field,
          };
        });
        if (!warranty_option.unique_id) {
          if (product && product.product_type === "equipment") {
            warranty_option.unique_id = product.serial_number;
          }
        }
        WarrantyEventBus.$emit("update:default-item-warranty", {
          current: index,
          data: {
            defaultItemWarranty: defaultWarranty,
            warrantyOption: warranty_option,
          },
        });
      }
    },
    updateWarrantyLineItem(row) {
      const _this = this;
      const {
        product_id,
        id,
        warranty_data,
        warranty_start_date,
        warranty_unique_id,
        warranty_description,
      } = row;
      _this.productLoading = true;
      _this
        .getCustomLineItems(product_id)
        .then((response) => {
          const product = _this.lodash.find(response, ["id", product_id]);
          _this.addCustomProduct({
            item: product,
            id: id,
            warranty: {
              data: warranty_data,
              description: warranty_description,
              start_date: warranty_start_date,
              unique_id: warranty_unique_id,
            },
          });
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.productLoading = false;
        });
    },
    addCustomProduct(param) {
      const _this = this;
      try {
        const { item, id, warranty } = _this.lodash.cloneDeep(param);
        const warrantyActive = +!!(
          _this.lodash.isEmpty(warranty.data) === false &&
          _this.lodash.isArray(warranty.data) === true
        );
        let warrantyObject = [];
        if (warrantyActive) {
          warrantyObject = warranty.data.map((row) => {
            return {
              id: row.id,
              warranty: _this.lodash.toSafeInteger(row.warranty) || null,
              value: _this.lodash.toSafeInteger(row.value),
              field: row.field,
              unique_id: null,
            };
          });
        }
        const taxObject =
          _this.levelOneHide === false &&
          _this.lodash.isEmpty(item.tax) === false
            ? item.tax
            : null;
        const rate =
          item.product_type == "goods"
            ? _this.lodash.toNumber(item.selling_cost)
            : _this.lodash.toNumber(item.charges);
        _this.lineItems.push({
          id: null,
          group_me: null,
          group_active: false,
          group_primary: false,
          type: "product",
          product: item,
          description: item.description,
          quantity: 1,
          quantity_type: "block",
          tax: taxObject ? taxObject.id : null,
          taxObject: taxObject ?? null,
          discount: 0.0,
          rate: rate ?? 0.0,
          default_rate: rate ?? 0.0,
          total: rate ?? 0.0,
          warranty: warrantyActive,
          warranty_line_item_id: id,
          warranty_object: warrantyObject,
          warranty_option: {
            unique_id: warranty.unique_id,
            start_date: warranty.start_date,
            description: warranty.description,
          },
        });

        _this.item = {};
        _this.calculateGrandTotal();
      } catch (error) {
        _this.logError(error);
      }
    },
  },
  mounted() {
    this.getLineItems();
    this.getTaxes();
    this.getUoms();
    if (this.isSingle) {
      this.getEditOptions();
    }
  },
  created() {
    const _this = this;

    LineItemEventBus.$on("update:warranty-line-item", (row) => {
      _this.updateWarrantyLineItem(row);
    });

    WarrantyEventBus.$on("update:manage-warranty", ({ current, data }) => {
      if (
        typeof current == "number" &&
        _this.lodash.isEmpty(data.defaultItemWarranty) === false &&
        _this.lodash.isArray(data.defaultItemWarranty) === true &&
        _this.lodash.isEmpty(_this.lineItems[current]) === false
      ) {
        _this.lineItems[current].warranty_object = data.defaultItemWarranty;
        _this.lineItems[current].warranty_option = data.warrantyOption;
        _this.calculateGrandTotal();
      }
    });
  },
  computed: {
    levelOneHide() {
      return this.isQuotation || this.isContract;
    },
    allowItemSelection() {
      if (this.isSingle && !this.isNewItem) {
        return false;
      }
      return true;
    },
  },
};
</script>
