<template>
  <v-row justify="center">
    <v-dialog
      scrollable
      origin="top center"
      v-model="warrantyDialog"
      persistent
      content-class="warranty-dialog"
      :max-width="dialogWidth"
    >
      <v-card>
        <v-card-title
          class="headline dialog-custom-header white-background custom-border-bottom custom-header-blue-text"
        >
          Manage Warranty
        </v-card-title>
        <v-card-text class="p-8 font-size-16">
          <!-- <perfect-scrollbar
            :options="{ suppressScrollX: true }"
            class="scroll"
            style="max-height: 60vh; position: relative"
          > -->
          <v-form
            ref="warrantyForm"
            v-model.trim="formValid"
            lazy-validation
            v-on:submit.stop.prevent="updateWarranty"
          >
            <v-container fluid>
              <v-row>
                <v-col md="6" class="py-0">
                  <label class="font-weight-600">Start Date</label>
                  <v-menu
                    v-model.trim="startDatePicker"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        dense
                        filled
                        label="Start Date"
                        :rules="[
                          () =>
                            !!formattedStartDate || 'Start Date is required',
                        ]"
                        prepend-inner-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        solo
                        flat
                        :value="formattedStartDate"
                        color="cyan"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model.trim="warrantyOption.start_date"
                      color="cyan"
                      v-on:input="startDatePicker = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col md="6" class="py-0">
                  <label class="font-weight-600">Serial No.</label>
                  <v-text-field
                    dense
                    filled
                    :rules="[
                      () =>
                        !!warrantyOption.unique_id || 'Serial No. is required',
                    ]"
                    color="cyan"
                    label="Serial No."
                    solo
                    flat
                    v-model.trim="warrantyOption.unique_id"
                  ></v-text-field>
                </v-col>
                <v-col md="12" class="py-0">
                  <label class="font-weight-600">Remarks</label>
                  <v-textarea
                    v-model.trim="warrantyOption.description"
                    auto-grow
                    dense
                    filled
                    solo
                    flat
                    color="cyan"
                    label="Remarks"
                    row-height="20"
                  ></v-textarea>
                </v-col>
              </v-row>
              <table class="width-100">
                <tr>
                  <td colspan="3">
                    <label class="font-weight-600">Duration</label>
                  </td>
                </tr>
                <tr
                  v-for="(warranty, index) in defaultItemWarranty"
                  :key="index"
                >
                  <td>
                    <v-text-field
                      dense
                      v-mask="'###'"
                      filled
                      label="Duration"
                      :rules="[
                        () => !!warranty.value || 'Duration is required',
                      ]"
                      solo
                      flat
                      color="cyan"
                      class="pr-2 width-100"
                      v-model.trim="warranty.value"
                    ></v-text-field>
                  </td>
                  <td>
                    <v-select
                      :items="warrantyDurationTypes"
                      dense
                      filled
                      solo
                      flat
                      :rules="[
                        () => !!warranty.field || 'Duration Type is required',
                      ]"
                      class="pl-2 pr-2 width-100"
                      v-model.trim="warranty.field"
                      label="Duration Type"
                      color="cyan"
                      item-text="text"
                      item-value="value"
                      item-color="cyan"
                    ></v-select>
                  </td>
                  <td style="vertical-align: top">
                    <template v-if="index > 0">
                      <v-btn
                        class="mx-2"
                        color="red lighten-1"
                        dark
                        fab
                        small
                        v-on:click="removeWarranty(index, warranty.id)"
                      >
                        <v-icon dark> mdi-minus</v-icon>
                      </v-btn>
                    </template>
                    <template v-else>
                      <v-btn
                        class="mx-2"
                        color="cyan"
                        dark
                        fab
                        small
                        v-on:click="pushWarranty"
                      >
                        <v-icon dark> mdi-plus</v-icon>
                      </v-btn>
                    </template>
                  </td>
                </tr>
              </table>
            </v-container>
          </v-form>
          <!-- </perfect-scrollbar> -->
        </v-card-text>
        <v-divider class="my-0"></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <div class="warrant-manage-action">
            <v-btn
              :disabled="!formValid"
              v-on:click="updateWarranty"
              class="mx-2 custom-bold-button white--text"
              color="cyan"
              >Update
            </v-btn>
            <v-btn
              v-on:click="$emit('remove:manage-warranty', currentIndex)"
              class="mx-2 custom-grey-border custom-bold-button"
              >Remove Warranty
            </v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<style scoped>
.v-dialog__content {
  align-items: start;
}
</style>

<script>
import WarrantyMixin from "@/core/lib/warranty/warranty.mixin";

export default {
  name: "manage-warranty",
  mixins: [WarrantyMixin],
  props: {
    dialogWidth: {
      type: Number,
      default: 768,
    },
  },
};
</script>
