<template>
  <CreateUpdateTemplate
    :customClass="'job-create'"
    v-if="getPermission('job:create')"
  >
    <template v-slot:header-title>
      <h1 class="form-title">
        <v-layout>
          <v-flex class="flex-grow-0"> Job For </v-flex>
          <v-flex
            class="m-0 form-title-create-link pl-2"
            link
            :class="{ 'text-truncate': !lodash.isEmpty(customer) }"
          >
            <template v-if="lodash.isEmpty(customer)">Client Name</template>
            <template v-else>{{ customer.display_name }}</template>
            <v-icon
              link
              large
              color="cyan"
              class="mx-2"
              v-if="lodash.isEmpty(customer)"
              >mdi-plus-circle-outline</v-icon
            >
          </v-flex>
        </v-layout>
      </h1>
    </template>
    <template v-slot:header-action>
      <v-btn
        v-on:click="goBack"
        :disabled="formLoading || isPageLoading"
        class="mx-2 custom-grey-border custom-bold-button"
        depressed
      >
        Cancel
      </v-btn>
      <v-btn
        :disabled="!formValid || formLoading || isPageLoading"
        :loading="formLoading"
        class="mx-2 custom-bold-button white--text"
        color="cyan"
        v-on:click="updateOrCreate"
      >
        Save Job
      </v-btn>
    </template>
    <template v-slot:body>
      <v-form
        ref="jobForm"
        v-model.trim="formValid"
        lazy-validation
        v-on:submit.stop.prevent="updateOrCreate"
      >
        <!-- <perfect-scrollbar
          :options="{ suppressScrollX: true }"
          class="scroll custom-box-top-inner-shadow"
          style="max-height: 70vh; position: relative"
        > -->
        <div class="p-5 pt-0">
          <v-row>
            <v-col cols="7" class="pb-0">
              <div>
                <label class="font-weight-700 font-size-16">Job Title</label>
                <v-text-field
                  v-model.trim="jobCreate.job_title"
                  dense
                  filled
                  label="Title"
                  solo
                  flat
                  :disabled="isPageLoading"
                  color="cyan"
                  :rules="[
                    validateRules.required(jobCreate.job_title, 'Job Title'),
                    validateRules.minLength(
                      jobCreate.job_title,
                      'Job Title',
                      1
                    ),
                    validateRules.maxLength(
                      jobCreate.job_title,
                      'Job Title',
                      100
                    ),
                  ]"
                ></v-text-field>
                <v-textarea
                  v-model.trim="jobCreate.description"
                  auto-grow
                  dense
                  filled
                  :disabled="isPageLoading"
                  color="cyan"
                  label="Description"
                  solo
                  flat
                  :rules="[
                    validateRules.minLength(
                      jobCreate.description,
                      'Description',
                      1
                    ),
                    validateRules.maxLength(
                      jobCreate.description,
                      'Description',
                      1024
                    ),
                  ]"
                  row-height="25"
                ></v-textarea>
              </div>
              <div>
                <v-row>
                  <v-col v-if="lodash.isEmpty(property) === false" cols="6">
                    <label class="font-weight-700 font-size-16"
                      >Property address</label
                    >
                    <div>
                      <p
                        v-if="property.property_address"
                        class="mb-0 font-size-16 text-ellipsis width-300px"
                      >
                        {{ property.property_address }}
                      </p>
                    </div>
                  </v-col>
                  <v-col
                    v-if="lodash.isEmpty(contact_person) === false"
                    cols="6"
                  >
                    <label class="font-weight-700 font-size-16"
                      >Contact details</label
                    >
                    <div>
                      <p
                        v-if="contact_person.display_name"
                        class="mb-0 font-size-16"
                      >
                        <template
                          v-if="changeAttention || !jobCreate.attention"
                        >
                          <v-text-field
                            v-model.trim="jobCreate.attention"
                            :rules="[
                              validateRules.required(
                                jobCreate.attention,
                                'Attention'
                              ),
                              validateRules.minLength(
                                jobCreate.attention,
                                'Attention',
                                1
                              ),
                              validateRules.maxLength(
                                jobCreate.attention,
                                'Attention',
                                100
                              ),
                            ]"
                            dense
                            filled
                            :disabled="isPageLoading"
                            color="cyan"
                            label="Attention"
                            solo
                            flat
                            hide-details
                            @keydown.esc="changeAttention = false"
                            @keydown.enter="changeAttention = false"
                            @keydown.tab="changeAttention = false"
                            v-on:change="changeAttention = false"
                          ></v-text-field>
                        </template>
                        <template v-else>
                          <div class="d-flex">
                            <p class="mb-0 text-ellipsis width-300px">
                              {{ jobCreate.attention }}
                            </p>
                            <v-btn
                              text
                              small
                              :disabled="isPageLoading"
                              class="custom-bold-button"
                              v-on:click="changeAttention = true"
                              color="cyan"
                            >
                              Change
                            </v-btn>
                          </div>
                        </template>
                      </p>
                      <p
                        v-if="contact_person.primary_phone"
                        class="mb-0 font-size-16 text-ellipsis width-300px"
                      >
                        {{ contact_person.primary_phone }}
                      </p>
                      <p
                        v-if="contact_person.primary_email"
                        class="mb-0 font-size-16 text-ellipsis width-300px"
                      >
                        {{ contact_person.primary_email }}
                      </p>
                    </div>
                  </v-col>
                </v-row>
              </div>
            </v-col>
            <v-col cols="5" class="pb-0">
              <table class="width-100">
                <tr>
                  <td colspan="3" class="font-weight-700 font-size-16 pb-2">
                    Job details
                  </td>
                </tr>
                <tr>
                  <td class="font-size-16 pb-2" width="200">Job number</td>
                  <td class="font-weight-700 font-size-16 pb-2" width="150">
                    {{ jobCreate.barcode }}
                  </td>
                  <td class="font-size-16 pb-2">
                    <v-btn
                      text
                      small
                      :disabled="isPageLoading"
                      class="custom-bold-button"
                      v-on:click="barcodeDialog = true"
                      color="cyan"
                    >
                      Change
                    </v-btn>
                  </td>
                </tr>
                <tr>
                  <td colspan="3">
                    <v-text-field
                      v-model.trim="jobCreate.reference"
                      dense
                      filled
                      :disabled="isPageLoading"
                      color="cyan"
                      label="Reference #"
                      :rules="[
                        validateRules.minLength(
                          jobCreate.reference,
                          'Reference',
                          1
                        ),
                        validateRules.maxLength(
                          jobCreate.reference,
                          'Reference',
                          100
                        ),
                      ]"
                      solo
                      flat
                    ></v-text-field>
                  </td>
                </tr>
                <tr v-if="false">
                  <td colspan="3">
                    <v-autocomplete
                      v-model.trim="jobCreate.project"
                      :items="projectList"
                      dense
                      filled
                      :disabled="isPageLoading"
                      clearable
                      color="cyan"
                      item-color="cyan"
                      item-text="barcode"
                      item-value="id"
                      label="Project"
                      v-on:change="updateDefaultRef('project')"
                      solo
                      flat
                    >
                      <template v-slot:no-data>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title
                              v-html="'No Project Found.'"
                            ></v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                    </v-autocomplete>
                  </td>
                </tr>
                <tr v-if="false">
                  <td colspan="3">
                    <v-autocomplete
                      v-model.trim="jobCreate.contract"
                      :items="contractList"
                      dense
                      filled
                      :disabled="isPageLoading"
                      clearable
                      color="cyan"
                      item-color="cyan"
                      item-text="barcode"
                      item-value="id"
                      label="Contract"
                      v-on:change="updateDefaultRef('contract')"
                      solo
                      flat
                    >
                      <template v-slot:no-data>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title
                              v-html="'No Contract Found.'"
                            ></v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                    </v-autocomplete>
                  </td>
                </tr>
                <tr>
                  <td colspan="3">
                    <v-autocomplete
                      v-model.trim="jobCreate.jobType"
                      :items="jobTypeList"
                      dense
                      filled
                      :disabled="isPageLoading"
                      color="cyan"
                      item-color="cyan"
                      item-text="value"
                      item-value="id"
                      label="Job Type"
                      solo
                      flat
                      multiple
                      :rules="[
                        validateRules.required(jobCreate.jobType, 'Job Type'),
                      ]"
                      return-object
                    >
                      <template v-slot:no-data>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title
                              v-html="'No Job Type Found.'"
                            ></v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                    </v-autocomplete>
                  </td>
                </tr>
                <tr>
                  <td colspan="3">
                    <v-autocomplete
                      v-model.trim="jobCreate.priority"
                      :items="jobPriorityList"
                      dense
                      filled
                      :disabled="isPageLoading"
                      color="cyan"
                      item-color="cyan"
                      item-text="value"
                      item-value="id"
                      label="Priority"
                      solo
                      flat
                      :rules="[
                        validateRules.required(jobCreate.priority, 'Priority'),
                      ]"
                      return-object
                    >
                      <template v-slot:no-data>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title
                              v-html="'No Priority Found.'"
                            ></v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                    </v-autocomplete>
                  </td>
                </tr>
                <tr>
                  <td colspan="3">
                    <v-autocomplete
                      v-model.trim="jobCreate.scheduleTab"
                      :items="jobScheduleTypeList"
                      dense
                      filled
                      :disabled="isPageLoading"
                      color="cyan"
                      item-color="cyan"
                      item-text="value"
                      item-value="id"
                      label="Schedule Type"
                      :rules="[
                        validateRules.required(
                          jobCreate.scheduleTab,
                          'Schedule Type'
                        ),
                      ]"
                      solo
                      flat
                    >
                      <template v-slot:no-data>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title
                              v-html="'No Schedule Type Found.'"
                            ></v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                    </v-autocomplete>
                  </td>
                </tr>
              </table>
            </v-col>
            <v-col cols="12" class="pt-0 job-schedule">
              <v-container fluid>
                <v-tabs
                  v-model="jobCreate.scheduleTab"
                  background-color="transparent"
                  centered
                  :disabled="isPageLoading"
                  color="cyan"
                  icons-and-text
                >
                  <v-tab href="#one-off">
                    <div class="custom-tab-title d-flex">
                      <div class="custom-tab-icon pr-2">
                        <v-icon left large color="gray">mdi-calendar</v-icon>
                      </div>
                      <div class="text-left">
                        <h3 class="text-capitalize">One-Off Job</h3>
                        <p class="m-0 text-capitalize">
                          A one time job with one or more visits
                        </p>
                      </div>
                    </div>
                  </v-tab>
                  <v-tab href="#recurring">
                    <div class="custom-tab-title d-flex">
                      <div class="custom-tab-icon pr-2">
                        <v-icon left large color="gray"
                          >mdi-calendar-multiple</v-icon
                        >
                      </div>
                      <div class="text-left">
                        <h3 class="text-capitalize">Recurring Job</h3>
                        <p class="m-0 text-capitalize">
                          A contract job with repeating visits
                        </p>
                      </div>
                    </div>
                  </v-tab>
                  <v-tabs-items v-model="jobCreate.scheduleTab">
                    <v-tab-item :value="'one-off'">
                      <v-row>
                        <v-col :md="showCalendar ? 4 : 12">
                          <OneOffSchedule
                            :showCalendar.sync="showCalendar"
                            :pageLoading.sync="isPageLoading"
                          ></OneOffSchedule>
                        </v-col>
                        <v-col v-if="showCalendar" md="8" dense>
                          <Calendar
                            :showCalendar="showCalendar"
                            :pageLoading.sync="isPageLoading"
                          ></Calendar>
                        </v-col>
                      </v-row>
                    </v-tab-item>
                    <v-tab-item :value="'recurring'">
                      <v-row>
                        <v-col :md="showCalendar ? 4 : 12">
                          <RecurringSchedule
                            :showCalendar.sync="showCalendar"
                            :pageLoading.sync="isPageLoading"
                          ></RecurringSchedule>
                        </v-col>
                        <v-col v-if="showCalendar" md="8" dense>
                          <Calendar
                            :showCalendar="showCalendar"
                            :pageLoading.sync="isPageLoading"
                          ></Calendar>
                        </v-col>
                      </v-row>
                    </v-tab-item>
                  </v-tabs-items>
                </v-tabs>
              </v-container>
            </v-col>
            <v-col cols="12" v-if="getPermission('line-item:create')">
              <v-container fluid>
                <v-card flat class="custom-grey-border remove-border-radius">
                  <v-card-title class="headline grey lighten-4">
                    <h3
                      class="font-weight-700 custom-headline color-custom-blue"
                    >
                      Line Items
                    </h3>
                  </v-card-title>
                  <v-card-text ref="lineItem" class="p-6 font-size-16">
                    <v-row dense>
                      <v-col cols="12" class="px-4">
                        <v-radio-group
                          class="m-0"
                          hide-details
                          v-model="lineItemType"
                          row
                        >
                          <v-radio
                            label="New"
                            color="cyan"
                            value="new"
                          ></v-radio>
                          <v-radio
                            label="Warranty"
                            color="cyan"
                            value="maintenance"
                          ></v-radio>
                          <v-radio
                            label="Contract"
                            color="cyan"
                            value="contract"
                          ></v-radio>
                        </v-radio-group>
                      </v-col>
                      <v-col
                        v-if="lineItemType == 'maintenance'"
                        cols="12"
                        class="px-4"
                      >
                        <v-simple-table
                          fixed-header
                          class="custom-grey-border"
                          height="388px"
                        >
                          <template v-slot:default>
                            <thead>
                              <tr>
                                <th class="font-size-16 pb-2">#</th>
                                <th class="pl-5 font-size-16 pb-2">
                                  Product/Equipment
                                </th>
                                <th class="pl-5 font-size-16 pb-2">
                                  Warranty Serial No.
                                </th>
                                <th class="pl-5 font-size-16 pb-2">
                                  Warranty Duration
                                </th>
                                <th class="pl-5 font-size-16 pb-2">
                                  Warranty Status
                                </th>
                                <th class="pl-5 font-size-16 pb-2"></th>
                              </tr>
                            </thead>
                            <tbody v-if="customerLoading">
                              <tr v-for="key in 3" :key="key">
                                <td>
                                  <v-skeleton-loader
                                    class="custom-skeleton"
                                    type="text"
                                  ></v-skeleton-loader>
                                </td>
                                <td>
                                  <v-skeleton-loader
                                    class="custom-skeleton"
                                    type="text"
                                  ></v-skeleton-loader>
                                </td>
                                <td>
                                  <v-skeleton-loader
                                    class="custom-skeleton"
                                    type="text"
                                  ></v-skeleton-loader>
                                </td>
                                <td>
                                  <v-skeleton-loader
                                    class="custom-skeleton"
                                    type="text"
                                  ></v-skeleton-loader>
                                </td>
                                <td>
                                  <v-skeleton-loader
                                    class="custom-skeleton"
                                    type="text"
                                  ></v-skeleton-loader>
                                </td>
                                <td>
                                  <v-skeleton-loader
                                    class="custom-skeleton"
                                    type="text"
                                  ></v-skeleton-loader>
                                </td>
                              </tr>
                            </tbody>
                            <template v-else>
                              <tbody v-if="lineItemWarranty.length > 0">
                                <tr
                                  v-for="(row, index) in lineItemWarranty"
                                  :key="index"
                                >
                                  <td>{{ index + 1 }}</td>
                                  <td class="pl-5 font-size-16 py-2">
                                    <v-chip
                                      small
                                      label
                                      class="custom-status font-weight-600 custom-grey-border text-uppercase"
                                      text-color="white"
                                      :color="
                                        row.product_type == 'goods'
                                          ? 'cyan darken-1'
                                          : 'orange darken-1'
                                      "
                                    >
                                      {{ getProductType(row.product_type) }}
                                    </v-chip>
                                    <p class="m-0 custom-nowrap-ellipsis">
                                      {{ row.product_name }}
                                    </p>
                                  </td>
                                  <td class="pl-5 font-size-16 py-2">
                                    <p class="m-0 custom-nowrap-ellipsis">
                                      {{ row.warranty_unique_id }}
                                    </p>
                                  </td>
                                  <td class="pl-5 font-size-16 py-2">
                                    <p class="m-0 custom-nowrap-ellipsis">
                                      <b>Start: </b
                                      >{{ formatDate(row.warranty_start_date) }}
                                    </p>
                                    <p class="m-0 custom-nowrap-ellipsis">
                                      <b>End: </b
                                      >{{ formatDate(row.warranty_end_date) }}
                                    </p>
                                  </td>
                                  <td class="pl-5 font-size-16 py-2">
                                    <p class="m-0 custom-nowrap-ellipsis">
                                      <template v-if="row.is_expired">
                                        <v-chip
                                          small
                                          label
                                          class="mb-1 custom-status font-weight-600 custom-grey-border text-uppercase"
                                          text-color="white"
                                          color="red lighten-1"
                                        >
                                          Expired
                                        </v-chip>
                                        <br />
                                        <b
                                          >Expired on
                                          {{
                                            formatDate(row.warranty_end_date)
                                          }}
                                          midnight</b
                                        >
                                      </template>
                                      <template v-else>
                                        <v-chip
                                          small
                                          label
                                          class="mb-1 custom-status font-weight-600 custom-grey-border text-uppercase"
                                          text-color="white"
                                          color="cyan lighten-1"
                                        >
                                          Active
                                        </v-chip>
                                        <br />
                                        <b
                                          >Expiring on
                                          {{
                                            formatDate(row.warranty_end_date)
                                          }}
                                          midnight</b
                                        >
                                      </template>
                                    </p>
                                  </td>
                                  <td>
                                    <v-btn
                                      class="mx-2 my-2 custom-bold-button white--text"
                                      color="cyan"
                                      small
                                      v-on:click="addWarrantyItemConfirm(row)"
                                      ><v-icon left>mdi-plus</v-icon>
                                      Add
                                    </v-btn>
                                  </td>
                                </tr>
                              </tbody>
                              <tbody v-else>
                                <tr>
                                  <td colspan="6">
                                    <p
                                      class="m-0 row-not-found text-center font-weight-500 font-size-16"
                                    >
                                      <img
                                        :src="
                                          $assetURL('media/error/empty.png')
                                        "
                                        class="row-not-found-image"
                                      />
                                      Uhh... There are no product/equipment at
                                      the moment.
                                    </p>
                                  </td>
                                </tr>
                              </tbody>
                            </template>
                          </template>
                        </v-simple-table>
                      </v-col>
                      <v-col
                        v-if="lineItemType == 'contract'"
                        cols="12"
                        class="px-4"
                      >
                        <v-simple-table
                          fixed-header
                          class="custom-grey-border"
                          height="388px"
                        >
                          <template v-slot:default>
                            <thead>
                              <tr>
                                <th class="font-size-16 pb-2">#</th>
                                <th class="pl-5 font-size-16 pb-2">
                                  Product/Equipment
                                </th>
                                <th class="pl-5 font-size-16 pb-2">
                                  Warranty Serial No.
                                </th>
                                <th class="pl-5 font-size-16 pb-2">
                                  Warranty Duration
                                </th>
                                <th class="pl-5 font-size-16 pb-2">
                                  Warranty Status
                                </th>
                                <th class="pl-5 font-size-16 pb-2"></th>
                              </tr>
                            </thead>
                            <tbody v-if="customerLoading">
                              <tr v-for="key in 3" :key="key">
                                <td>
                                  <v-skeleton-loader
                                    class="custom-skeleton"
                                    type="text"
                                  ></v-skeleton-loader>
                                </td>
                                <td>
                                  <v-skeleton-loader
                                    class="custom-skeleton"
                                    type="text"
                                  ></v-skeleton-loader>
                                </td>
                                <td>
                                  <v-skeleton-loader
                                    class="custom-skeleton"
                                    type="text"
                                  ></v-skeleton-loader>
                                </td>
                                <td>
                                  <v-skeleton-loader
                                    class="custom-skeleton"
                                    type="text"
                                  ></v-skeleton-loader>
                                </td>
                                <td>
                                  <v-skeleton-loader
                                    class="custom-skeleton"
                                    type="text"
                                  ></v-skeleton-loader>
                                </td>
                                <td>
                                  <v-skeleton-loader
                                    class="custom-skeleton"
                                    type="text"
                                  ></v-skeleton-loader>
                                </td>
                              </tr>
                            </tbody>
                            <template v-else>
                              <tbody v-if="lineItemExpiredWarranty.length > 0">
                                <tr
                                  v-for="(
                                    row, index
                                  ) in lineItemExpiredWarranty"
                                  :key="index"
                                >
                                  <td>{{ index + 1 }}</td>
                                  <td class="pl-5 font-size-16 py-2">
                                    <v-chip
                                      small
                                      label
                                      class="custom-status font-weight-600 custom-grey-border text-uppercase"
                                      text-color="white"
                                      :color="
                                        row.product_type == 'goods'
                                          ? 'cyan darken-1'
                                          : 'orange darken-1'
                                      "
                                    >
                                      {{ getProductType(row.product_type) }}
                                    </v-chip>
                                    <p class="m-0 custom-nowrap-ellipsis">
                                      {{ row.product_name }}
                                    </p>
                                  </td>
                                  <td class="pl-5 font-size-16 py-2">
                                    <p class="m-0 custom-nowrap-ellipsis">
                                      {{ row.warranty_unique_id }}
                                    </p>
                                  </td>
                                  <td class="pl-5 font-size-16 py-2">
                                    <p class="m-0 custom-nowrap-ellipsis">
                                      <b>Start: </b
                                      >{{ formatDate(row.warranty_start_date) }}
                                    </p>
                                    <p class="m-0 custom-nowrap-ellipsis">
                                      <b>End: </b
                                      >{{ formatDate(row.warranty_end_date) }}
                                    </p>
                                  </td>
                                  <td class="pl-5 font-size-16 py-2">
                                    <p class="m-0 custom-nowrap-ellipsis">
                                      <template v-if="row.is_expired">
                                        <v-chip
                                          small
                                          label
                                          class="mb-1 custom-status font-weight-600 custom-grey-border text-uppercase"
                                          text-color="white"
                                          color="red lighten-1"
                                        >
                                          Expired
                                        </v-chip>
                                        <br />
                                        <b
                                          >Expired on
                                          {{
                                            formatDate(row.warranty_end_date)
                                          }}
                                          midnight</b
                                        >
                                      </template>
                                      <template v-else>
                                        <v-chip
                                          small
                                          label
                                          class="mb-1 custom-status font-weight-600 custom-grey-border text-uppercase"
                                          text-color="white"
                                          color="cyan lighten-1"
                                        >
                                          Active
                                        </v-chip>
                                        <br />
                                        <b
                                          >Expiring on
                                          {{
                                            formatDate(row.warranty_end_date)
                                          }}
                                          midnight</b
                                        >
                                      </template>
                                    </p>
                                  </td>
                                  <td>
                                    <v-btn
                                      class="mx-2 my-2 custom-bold-button white--text"
                                      color="cyan"
                                      small
                                      v-on:click="addWarrantyItemConfirm(row)"
                                      ><v-icon left>mdi-plus</v-icon>
                                      Add
                                    </v-btn>
                                  </td>
                                </tr>
                              </tbody>
                              <tbody v-else>
                                <tr>
                                  <td colspan="6">
                                    <p
                                      class="m-0 row-not-found text-center font-weight-500 font-size-16"
                                    >
                                      <img
                                        :src="
                                          $assetURL('media/error/empty.png')
                                        "
                                        class="row-not-found-image"
                                      />
                                      Uhh... There are no product/equipment at
                                      the moment.
                                    </p>
                                  </td>
                                </tr>
                              </tbody>
                            </template>
                          </template>
                        </v-simple-table>
                      </v-col>
                      <v-col cols="12" class="pt-0 pb-0">
                        <JobLineItem
                          isTicket
                          :updateData.sync="duplicateLineItems"
                          :pageLoading.sync="isPageLoading"
                        ></JobLineItem>
                      </v-col>
                      <v-col cols="12" class="pt-0" v-if="!isTicket">
                        <JobLineItemCalculation
                          :updateData.sync="duplicateJob"
                          :pageLoading.sync="isPageLoading"
                        ></JobLineItemCalculation>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-container>
            </v-col>
            <v-col cols="12">
              <JobInvoiceSchedule
                :pageLoading.sync="isPageLoading"
              ></JobInvoiceSchedule>
            </v-col>
            <v-col cols="12">
              <JobTermsConditions
                :updateData.sync="duplicateJob"
                :pageLoading.sync="isPageLoading"
              ></JobTermsConditions>
            </v-col>
            <v-col cols="12">
              <JobNotesAttachment
                :updateData.sync="duplicateJob"
                :pageLoading.sync="isPageLoading"
                :isTicket="isTicket"
              ></JobNotesAttachment>
            </v-col>
          </v-row>
        </div>
        <!-- </perfect-scrollbar> -->
      </v-form>
      <template v-if="warrantyLineItemDialog">
        <Dialog :commonDialog.sync="warrantyLineItemDialog">
          <template v-slot:title>
            <template v-if="warrantyLineItemFormData.product_type == 'goods'"
              >Product</template
            >
            <template
              v-else-if="warrantyLineItemFormData.product_type == 'equipment'"
              >Equipment</template
            >
            #{{ warrantyLineItemFormData.product_barcode }} -
            {{ warrantyLineItemFormData.product_name }}
          </template>
          <template v-slot:body>
            <v-container>
              <v-form
                ref="warrantyItemDialogForm"
                v-model.trim="warrantyItemDialogValid"
                lazy-validation
                v-on:submit.stop.prevent="addWarrantyItem"
              >
                <v-row>
                  <v-col md="12">
                    <label>Warranty Serial No.</label>
                    <v-text-field
                      v-model.trim="warrantyLineItemFormData.warranty_unique_id"
                      dense
                      filled
                      color="cyan"
                      label="Serial No."
                      :rules="[
                        validateRules.required(
                          warrantyLineItemFormData.warranty_unique_id,
                          'Serial No.'
                        ),
                        validateRules.minLength(
                          warrantyLineItemFormData.warranty_unique_id,
                          'Serial No.',
                          1
                        ),
                        validateRules.maxLength(
                          warrantyLineItemFormData.warranty_unique_id,
                          'Serial No.',
                          100
                        ),
                      ]"
                      solo
                      flat
                    ></v-text-field>
                  </v-col>
                  <v-col md="12">
                    <label>Warranty Remarks</label>
                    <v-textarea
                      v-model.trim="
                        warrantyLineItemFormData.warranty_description
                      "
                      dense
                      filled
                      color="cyan"
                      label="Remarks"
                      solo
                      flat
                    ></v-textarea>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
          </template>
          <template v-slot:action>
            <v-btn
              :disabled="!warrantyItemDialogValid"
              class="mx-2 custom-grey-border custom-bold-button text-white"
              color="cyan"
              v-on:click="addWarrantyItem"
            >
              Add
            </v-btn>
            <v-btn
              class="mx-2 custom-grey-border custom-bold-button"
              v-on:click="warrantyLineItemDialog = false"
            >
              Cancel
            </v-btn>
          </template>
        </Dialog>
      </template>
    </template>
    <template v-slot:barcode-setting>
      <BarcodeSetting
        endpoint="job"
        :barcodeDialog="barcodeDialog"
        :dialogWidth="600"
        :barcodeSetting.sync="barcodeSetting"
        v-on:close:barcode-dialog="barcodeDialog = false"
      ></BarcodeSetting>
    </template>
  </CreateUpdateTemplate>
</template>

<script>
import CommonMixin from "@/core/plugins/common-mixin";
import ValidationMixin from "@/core/plugins/validation-mixin";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import CreateUpdateTemplate from "@/view/pages/partials/Create-Update-Template.vue";
import JobLineItem from "@/view/pages/partials/Line-Item.vue";
import JobLineItemCalculation from "@/view/pages/partials/Line-Item-Calculation.vue";
import JobTermsConditions from "@/view/pages/partials/Terms-Conditions.vue";
import JobInvoiceSchedule from "@/view/pages/partials/Invoice-Schedule.vue";
import JobNotesAttachment from "@/view/pages/partials/Notes-Attachment.vue";
import BarcodeSetting from "@/view/pages/partials/Barcode-Setting.vue";
import Calendar from "@/view/pages/partials/Calendar.vue";
import OneOffSchedule from "@/view/pages/job/partials/One-Off-Schedule.vue";
import RecurringSchedule from "@/view/pages/job/partials/Recurring-Schedule.vue";
import { QUERY } from "@/core/services/store/request.module";
import JobMixin from "@/core/lib/job/job.mixin";
import Dialog from "@/view/pages/partials/Dialog";

export default {
  mixins: [CommonMixin, JobMixin, ValidationMixin],
  name: "job-create",
  title: "Create Job",
  data() {
    return {
      time: null,
      timePicker: null,
      customerLoading: true,
      lineItemType: "new",
      lineItemWarranty: new Array(),
      lineItemExpiredWarranty: new Array(),
      isTicket: true,
      jobCustomer: 0,
      jobContactPerson: 0,
      jobCustomerProperty: 0,
      job: 0,
      jobArr: {},
      changeAttention: false,
      barcodeDialog: false,
      pageLoading: true,
      projectList: [],
      contractList: [],
      lineItem: [],
      lineItemCalculation: {},
      termsCondition: null,
      noteAttachment: {},
      customer: {},
      property: {},
      contact_person: {},
      barcodeSetting: {},
    };
  },
  components: {
    Dialog,
    JobLineItem,
    JobLineItemCalculation,
    JobTermsConditions,
    JobNotesAttachment,
    JobInvoiceSchedule,
    BarcodeSetting,
    Calendar,
    OneOffSchedule,
    RecurringSchedule,
    CreateUpdateTemplate,
  },
  watch: {
    lineItemType(param) {
      if (param == "maintenance") {
        if (this.lodash.isEmpty(this.lineItemWarranty)) {
          this.getWarrantyByCustomer();
        }
      }
      if (param == "contract") {
        if (this.lodash.isEmpty(this.lineItemExpiredWarranty)) {
          this.getExpiredWarrantyByCustomer();
        }
      }
    },
  },
  methods: {
    updateDefaultRef(type) {
      if (type == "project") {
        const { project } = this.jobCreate;
        const projectObject = this.lodash.find(this.projectList, {
          id: project,
        });
        if (projectObject) {
          this.jobCreate.reference = projectObject.reference;
        }
      } else if (type == "contract") {
        const { contract } = this.jobCreate;
        const contractObject = this.lodash.find(this.contractList, {
          id: contract,
        });
        if (contractObject) {
          this.jobCreate.reference = contractObject.reference;
        }
      }
    },
    getWarrantyByCustomer() {
      const _this = this;
      _this.customerLoading = true;
      let filter = {
        /*search: _this.filter.search || undefined,*/
        contract:
          _this.lodash.toSafeInteger(_this.jobCreate.contract) || undefined,
        customer: _this.lodash.toSafeInteger(_this.jobCustomer) || undefined,
      };
      _this.$store
        .dispatch(QUERY, {
          url: "warranty",
          data: { filter, status: "active" },
        })
        .then(({ data }) => {
          _this.lineItemWarranty = data.rows;
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.customerLoading = false;
        });
    },
    getExpiredWarrantyByCustomer() {
      const _this = this;
      _this.customerLoading = true;
      let filter = {
        /*search: _this.filter.search || undefined,*/
        contract:
          _this.lodash.toSafeInteger(_this.jobCreate.contract) || undefined,
        customer: _this.lodash.toSafeInteger(_this.jobCustomer) || undefined,
      };
      _this.$store
        .dispatch(QUERY, {
          url: "warranty",
          data: { filter, status: "expired" },
        })
        .then(({ data }) => {
          _this.lineItemExpiredWarranty = data.rows;
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.customerLoading = false;
        });
    },
    getAttributes(attributes) {
      const _this = this;
      _this.$store
        .dispatch(QUERY, {
          url: "job/options",
          data: {
            contract: _this.jobCreate.contract || null,
            attribute: attributes,
            customer: _this.jobCustomer || null,
            contact_person: _this.jobContactPerson || null,
            property: _this.jobCustomerProperty || null,
            "schedule-type": _this.jobCreate.scheduleTab == "one-off" ? 1 : 2,
          },
        })
        .then(({ data }) => {
          if (data.contract) {
            _this.jobCreate.job_title = data.contract.subject;
            _this.jobCreate.description = data.contract.description;
            const jobType = _this.lodash.find(data.ticket_types, {
              value: data.contract.contract_type,
            });
            _this.jobCreate.jobType = new Array(jobType);
          }

          _this.projectList = data.projects;
          _this.contractList = data.contracts;

          const project = this.lodash.toSafeInteger(this.$route.query.project);
          if (project && _this.projectList.length) {
            const selected_project = this.lodash.find(_this.projectList, {
              id: project,
            });
            if (selected_project) {
              this.jobCreate.reference = selected_project.reference;
            }
          }

          const contract = this.lodash.toSafeInteger(
            this.$route.query.contract
          );
          if (contract && _this.contractList.length) {
            const selected_contract = this.lodash.find(_this.contractList, {
              id: contract,
            });
            if (selected_contract) {
              this.jobCreate.reference = selected_contract.reference;
            }
          }

          _this.updateDuplicateAttributes({
            lineItems: data.contract_line_items,
          });

          if (_this.lodash.isEmpty(data.customer) === false) {
            _this.customer = data.customer;
          }
          if (_this.lodash.isEmpty(data.property) === false) {
            _this.property = data.property;
          }
          if (_this.lodash.isEmpty(data.contact_person) === false) {
            _this.contact_person = data.contact_person;
            _this.jobCreate.attention = _this.contact_person.display_name;
          }
          if (_this.lodash.isEmpty(data.barcode_setting) === false) {
            _this.barcodeSetting = data.barcode_setting;

            _this.duplicateJob = {
              term_conditions: _this.barcodeSetting.terms_conditions,
              admin_remark: _this.barcodeSetting.admin_note,
              client_remark: _this.barcodeSetting.client_note,
            };
          }
          if (data.barcode) {
            _this.jobCreate.barcode = data.barcode;
          }
          if (_this.lodash.isEmpty(data.ticket_types) === false) {
            _this.jobTypeList = data.ticket_types;
          }
        })
        .catch((error) => {
          _this.logError(error);
          _this.goBack();
        })
        .finally(() => {
          this.pageLoading = false;
        });
    },
    async loadDuplicateData(jobId) {
      const _this = this;
      try {
        let job = await _this.getDuplicateJob(jobId);
        let lineItems = await _this.getDuplicateJobLineItems(jobId);
        _this.updateDuplicateAttributes({ job, lineItems: lineItems.rows });
      } catch (error) {
        _this.logError(error);
      }
    },
    async loadQuotationData(quotationId) {
      const _this = this;
      try {
        let quotation = await _this.getQuotation(quotationId);
        let lineItems = await _this.getQuotationLineItems(quotationId);
        _this.updateQuotationAttributes({ quotation, lineItems: lineItems });
      } catch (error) {
        _this.logError(error);
      }
    },
  },
  beforeMount() {
    const _this = this;
    _this.jobCreate.project =
      _this.lodash.toSafeInteger(_this.$route.query.project) || null;
    _this.jobCustomer = _this.lodash.toSafeInteger(_this.$route.query.customer);
    _this.jobContactPerson = _this.lodash.toSafeInteger(
      _this.$route.query.contact_person
    );
    _this.jobCustomerProperty = _this.lodash.toSafeInteger(
      _this.$route.query.property
    );
    _this.jobCreate.project = _this.lodash.toSafeInteger(
      _this.$route.query.project
    );
    _this.jobCreate.contract = _this.lodash.toSafeInteger(
      _this.$route.query.contract
    );
    if (
      !_this.jobCustomer ||
      !_this.jobContactPerson ||
      !_this.jobCustomerProperty
    ) {
      _this.$router.go(-1);
    }

    let duplicateJobId = _this.lodash.toSafeInteger(
      _this.$route.query.duplicate
    );
    if (duplicateJobId > 0) {
      _this.loadDuplicateData(duplicateJobId);
    }

    let quotationId = _this.lodash.toSafeInteger(_this.$route.query.quotation);
    if (quotationId > 0) {
      _this.jobCreate.quote = quotationId;
      _this.loadQuotationData(quotationId);
    }
  },
  mounted() {
    const _this = this;
    _this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Job", route: "job" },
      { title: "Create" },
    ]);

    _this.getAttributes([
      "contract_line_items",
      "customer",
      "contact_person",
      "property",
      "barcode",
      "barcode_setting",
      "ticket_types",
    ]);
    _this.getTicketTypes();
  },
  computed: {
    isPageLoading() {
      return this.pageLoading || this.formLoading;
    },
    CityName() {
      return this.lodash.isEmpty(this.property.city) === false
        ? this.property.city.name
        : null;
    },
    StateName() {
      return this.lodash.isEmpty(this.property.state) === false
        ? this.property.state.name
        : null;
    },
    CountryName() {
      return this.lodash.isEmpty(this.property.country) === false
        ? this.property.country.name
        : null;
    },
  },
};
</script>
